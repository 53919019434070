import React from 'react';
import { withStyles } from '@material-ui/core';
import styles from 'components/commonStyles';
import { PageHeader, Seo, Footer } from 'components/common';
import PropTypes from 'prop-types';
import { siteUrl } from 'configs/constants';
import { Link } from 'gatsby';
import AllCase from './api-chooser/allcase';

const ApiChooser = ({ classes }) => (
  <>
    <Seo title="API chooser" url={`${siteUrl}/api-chooser`} />
    <PageHeader />
    <div className={classes.content} style={{ counterReset: 'inc' }}>
      <p style={{ marginBottom: 36 }}>
        What API suits you? Choose your business case!
      </p>
      <p>
        Before start reading this article we recommend you to meet our
        {' '}
        <span className={classes.textColor}>
          <Link to="/developers/data-domain/">
            Data domain
          </Link>
        </span>
      </p>
      <h3 style={{ margin: '60px 0 30px 0' }}>
        Programming guidelines
      </h3>
      <p style={{ lineHeight: '48px' }}>
        <span className={classes.textColor}>
          <Link
            to="/developers/json-rest-api-guidelines/"
          >
            JSON-REST API Guidelines
          </Link>
        </span>
        ,
        <br />
        <span className={classes.textColor}>
          <Link to="/developers/java/">Java</Link>
        </span>
        ,
        {' '}
        <span className={classes.textColor}>
          <Link to="/developers/dot-net/">.NET</Link>
        </span>
        ,
        <br />
        and
        {' '}
        <span className={classes.textColor}>
          <Link to="/developers/error-handling/">
            Error handling
          </Link>
        </span>
        .
      </p>
      <AllCase />
    </div>
    <Footer />
  </>
);

ApiChooser.propTypes = {
  classes: PropTypes.shape({
    content: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(ApiChooser);
